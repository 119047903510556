import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map, tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { Favorite, FavoriteOID, RESOURCE_TYPE } from '../../models/favorite';
import { FavoriteErrorHandler } from '../../services/FavoriteErrorHandler';
import { FavoritesResponse } from '../../services/FavoritesResponse';
import { HttpFavoritesGateway } from '../../services/HttpFavoritesGateway';
import { FavoriteSitesActions } from '../actions/favorite-site';

@Injectable()
export class FavoriteSiteService {
    constructor(
        private readonly favoritesGateway: HttpFavoritesGateway,
        private store: Store<AppState>,
        private favoriteErrorHandler: FavoriteErrorHandler,
    ) {}

    getFavoritesSites(): Observable<Favorite[]> {
        return this.favoritesGateway
            .getFavorites()
            .pipe(
                map(({ results }: FavoritesResponse) =>
                    results.filter((item) => item.resourceType === RESOURCE_TYPE.SITE),
                ),
            );
    }

    addFavorite(siteId: string): Observable<{ favoriteOID: FavoriteOID }> {
        return this.favoritesGateway.addFavorite(siteId).pipe(
            tap((favorite) => {
                this.store.dispatch(FavoriteSitesActions.favoriteAdded({ favorite }));
            }),
            catchError((err) => {
                const errorMessage = err.error.message;

                if (errorMessage === 'already_favorite') {
                    this.store.dispatch(FavoriteSitesActions.reloadRequested());
                    return of();
                }

                this.favoriteErrorHandler.handle(errorMessage);

                return throwError(err);
            }),
        );
    }

    removeFavorite(favoriteOID: FavoriteOID): Observable<void> {
        return this.favoritesGateway.removeFavorite(favoriteOID).pipe(
            tap(() => {
                this.store.dispatch(FavoriteSitesActions.favoriteRemoved({ favoriteOID }));
            }),
            catchError((err) => {
                const errorMessage = err.error.message;

                if (errorMessage === 'not_favorite') {
                    this.store.dispatch(FavoriteSitesActions.favoriteRemoved({ favoriteOID }));
                    return of();
                }

                this.favoriteErrorHandler.handle(errorMessage);

                return throwError(err);
            }),
        );
    }
}
