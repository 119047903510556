import { Injectable } from '@angular/core';
import { ErrorService } from '../../shared/services/error.service';

@Injectable()
export class FavoriteErrorHandler {
    private supportedErrors = ['wrong_customerId', 'favorite_limit', 'not_supported', 'unknown_gateway'];

    constructor(private errorService: ErrorService) {}

    handle(errorMessage: string): void {
        const toasterMessage = this.supportedErrors.includes(errorMessage)
            ? `ERROR_FAVORITE_${errorMessage.toUpperCase()}`
            : `ERROR_FAVORITE_UNKNOWN`;
        this.errorService.showToasterError(toasterMessage);
    }
}
