import { Timestamp } from '../../shared/common.types';
import { SiteOID } from '../../site-detail/models/Site';

export type FavoriteOID = string;

export type ResourceType = (typeof RESOURCE_TYPE)[keyof typeof RESOURCE_TYPE];
export const RESOURCE_TYPE = {
    SITE: 'SITE',
} as const;

export type ResourceOID = SiteOID;

export interface Favorite {
    favoriteOID: FavoriteOID;
    resourceType: ResourceType;
    resourceOID: ResourceOID;
    metadata: Record<string, unknown>;
    position: number;
    creationTime: Timestamp;
    lastUpdateTime: Timestamp;
}
