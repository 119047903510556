import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../../shared/injectionTokens';
import { BobHttpService } from '../../shared/services/bob-http.service';
import { SiteOID } from '../../site-detail/models/Site';
import { Favorite, FavoriteOID, RESOURCE_TYPE } from '../models/favorite';
import { FavoritesGateway } from './FavoritesGateway';
import { FavoritesResponse } from './FavoritesResponse';

const FAVORITES_ENDPOINT = '/favorite-api/public/v1';

@Injectable()
export class HttpFavoritesGateway implements FavoritesGateway {
    constructor(
        private bobHttpService: BobHttpService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    get applicationOID(): string {
        return this.appConfig.bobApi.favoriteApplicationOID;
    }

    getFavorites(): Observable<FavoritesResponse> {
        return this.bobHttpService.get(`${FAVORITES_ENDPOINT}/applications/${this.applicationOID}/favorites`);
    }

    addFavorite(siteOID: SiteOID): Observable<Favorite> {
        return this.bobHttpService.post(`${FAVORITES_ENDPOINT}/applications/${this.applicationOID}/favorites`, {
            resourceType: RESOURCE_TYPE.SITE,
            resourceOID: siteOID,
            metadata: {},
        });
    }

    removeFavorite(favoriteOID: FavoriteOID): Observable<void> {
        return this.bobHttpService.delete(`${FAVORITES_ENDPOINT}/favorites/${favoriteOID}`);
    }
}
